@import '../../Abstracto.scss';

#main-marketing-digital {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  div.primera-seccion {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 200px 16px 0;
    z-index: 5;

    h2 {
      font-family: Roboto Mono;
      font-weight: bold;
      margin: 0;
    }

    p {
      text-align: center;
      margin: 24px 0 0;
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      max-width: 510px;

      @media screen and (min-width: 1152px) {
        max-width: 592px;
      }

      @media screen and (min-width: 1440px) {
        max-width: 608px;
      }
    }
  }

  img.elipses {
    margin: 100px 0 0;
    width: 202px;
    box-shadow: none;
    border-radius: 0;
    z-index: 5;
  }

  div.segunda-seccion {
    margin: 100px 16px 0;
    z-index: 5;

    p {
      text-align: center;
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 50px;
      max-width: 510px;
      margin: 0;

      @media screen and (min-width: 1152px) {
        max-width: 592px;
      }
    }
  }

  div.primer-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 300px;
    align-items: center;
    justify-content: center;
    grid-auto-flow: row;
    grid-gap: 24px;
    margin: 100px 0 0;
    width: 90%;
    z-index: 4;
    padding-bottom: 100px;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }

    @media screen and (min-width: 1152px) {
      grid-template-columns: 33% 33%;
      grid-template-rows: 1fr;
    }

    div {
      display: flex;
      flex-direction: column;
      border-radius: 7px;
      height: auto;
      width: 100%;
      justify-items: center;
      align-items: center;
      padding: 90px 0;

      @media screen and (min-width: 1152px) {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      }

      img.image-primer-grid {
        box-shadow: none;
      }
      span {
        margin: 24px auto 0;
        font-family: Avenir LT Std;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        color: #ffffff;
        max-width: 240px;
        text-align: center;
      }
    }
  }

  div.segundo-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 0;
    width: 100%;
    z-index: 4;

    .animate__fadeInUp {
      padding: 0;

      div {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-items: center;
        align-items: center;
        padding: 60px 0;

        img {
          box-shadow: none;
        }
        span {
          margin: 24px auto 0;
          font-family: Avenir LT Std;
          font-style: normal;
          font-weight: 900;
          font-size: 30px;
          line-height: 30px;
          color: #ffffff;
          max-width: 240px;
          text-align: center;
        }
        p {
          font-family: Avenir LT Std;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
          color: #ffffff;
          text-align: center;
          width: auto;
          margin: 24px 16px 0;

          @media screen and (min-width: 1152px) {
            max-width: 708px;
          }
        }
        .listado {
          color: #ffffff;
          font-family: Avenir LT Std;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin: 24px auto 0;
          text-align: center;
        }
      }
    }
  }

  .naranja {
    background: #fc5936;
  }
  .aqua {
    background: #27e5c7;
  }
  .verde {
    background: #5abfb6;
  }
  .rojo {
    background: #b92e1f;
  }
}

#abstracto-marketing {
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    margin: 100px auto 0;
  }

  @media screen and (min-width: 1152px) {
    margin: 100px auto 0;
  }
}

#senor-marketing {
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    margin: 100px auto 0;
  }

  @media screen and (min-width: 1152px) {
    margin: 100px auto 0;
  }
}

#texto-historia-marketing {
  @media screen and (min-width: 1152px) {
    max-width: 708px;
    margin: 0 auto 60px;
  }
}

#fondo-padre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-image: url('../../assets/images/marketing/marketing-digital-hero-768.jpg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left;

  @media screen and (min-width: 768px) {
    background-image: url('../../assets/images/marketing/marketing-digital-hero-1152.jpg');
  }

  @media screen and (min-width: 1152px) {
    background-image: url('../../assets/images/marketing/marketing-digital-hero-1440.jpg');
  }
}
