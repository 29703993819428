$mobile: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1151px)';
$laptop: '(min-width: 1152px) and (max-width: 1439px)';
$desktop: '(min-width: 1440px)';

$naranja: #fc5936;
$negro: #000000;
$blanco: #ffffff;

//Colores
$background: #f7f7f7;
$background2: #e5e5e5;
$rojo: #bd2d15;
$beige: #efe2d2;
$marron: #a85640;
$azulClaro: #0bdae2;
$aqua: #00e7c5;
$verdeOscuro: #195e63;
$azul: #25bac1;
$gris: #d0cfcf;
$border: #c4c4c4;
$menta: #27e5c7;

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin onlyTablet {
  @media (min-width: 768px) and (max-width: 1151px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1152px) {
    @content;
  }
}

@mixin onlyLaptop {
  @media (min-width: 1152px) and (max-width: 1439px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1400px) {
    @content;
  }
}
