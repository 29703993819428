@import '../../Abstracto.scss';

#abstracto-plus-articulo-main {
  padding-top: 200px;
}

div.contiene-articulo {
  margin: 0 16px 0;

  @media screen and (min-width: 768px) {
    margin: 0 40px 0;
  }

  @media screen and (min-width: 1152px) {
    margin: 0 114px 0;
  }

  @media screen and (min-width: 1440px) {
    margin: 0 258px 0;
  }

  div.estructura-articulo {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    word-break: break-word;

    @media screen and (min-width: 1152px) {
      padding-top: 100px;
    }

    div.titulo {
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: #000000;

      @media screen and (min-width: 768px) {
        font-size: 30px;
        line-height: 35px;
      }
    }

    span.autor {
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-top: 29px;

      @media screen and (min-width: 768px) {
        margin-top: 40px;
      }
    }

    span.fecha {
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #000000;

      @media screen and (min-width: 768px) {
        margin-top: 10px;
      }
    }

    div.imagen {
      width: 100%;
      height: 508px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 29px;

      @media screen and (min-width: 768px) {
        height: 500px;
        margin-top: 40px;
      }
    }

    div.cuerpo {
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 35px;
      color: #000000;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img,
      iframe {
        display: block;
        text-align: justify;
        margin: 0;
        max-width: 100%;
        width: 288px;
        height: 162px;

        @media screen and (min-width: 768px) {
          width: 558px;
          height: 314px;
        }

        @media screen and (min-width: 1152px) {
          width: 740px;
          height: 416px;
        }
        @media screen and (min-width: 1440px) {
          width: 1028px;
          height: 578px;
        }
      }

      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  div.estructura-compartir {
    margin-top: 40px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-top: 2px solid #000000;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      margin-top: 100px;
      border-top: none;
      padding-top: 0;
    }

    div.linea-cover {
      @media screen and (min-width: 768px) {
        border-top: 2px solid #000000;
        width: 50%;
        margin-right: 24px;
      }

      @media screen and (min-width: 1152px) {
        width: 60%;
        margin-right: 24px;
      }
    }

    span.compartir-articulo {
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
    }

    .logos-compartir {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      a,
      span {
        display: flex;
        align-items: center;

        img.facebook {
          width: 20px;
          box-shadow: none;
          border-radius: 0;

          @media screen and (min-width: 768px) {
            margin-left: 24px;
          }
        }

        img.whatsapp {
          width: 20px;
          margin-left: 14px;
          box-shadow: none;
          border-radius: 0;
        }

        img.twitter {
          width: 21px;
          margin-left: 14px;
          margin-top: 1px;
          box-shadow: none;
          border-radius: 0;
        }
      }
    }
  }
}
