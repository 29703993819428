@import '../../Abstracto.scss';

#abstracto-plus-main {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background: #f7f7f7;

  .fondo-padre {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-image: url('../../assets/images/abstractoPlus/abstracto+-320.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
    padding: 130px 16px 0;
    color: white;
    text-align: center;
    font-family: Avenir LT Std;
    max-width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 768px) {
      background-image: url('../../assets/images/abstractoPlus/abstracto+-768.jpg');
      padding: 265px 130px 0;
      height: 100vh;
    }

    @media screen and (min-width: 1152px) {
      background-image: url('../../assets/images/abstractoPlus/abstracto+-1152.jpg');
      padding: 200px 272px 0;
    }

    @media screen and (min-width: 1440px) {
      background-image: url('../../assets/images/abstractoPlus/abstracto+-1440.jpg');
      padding: 200px 415px 0;
    }

    h1 {
      margin-top: 0;
    }

    img.abstract-plus-3D {
      margin-top: 60px;
      margin-bottom: 145px;
      box-shadow: none;

      @media screen and (min-width: 768px) {
        width: 510px;
        margin-bottom: 100px;
      }

      @media screen and (min-width: 1152px) {
        width: 710px;
        margin-bottom: 30px;
      }
    }

    .flecha-seleccionar-plus {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 100%;
      line-height: 50px;

      span {
        width: 100%;
        font-size: 16px;
      }

      img {
        box-shadow: none;
        margin: 0;
      }
    }
  }
  .flecha-bounce {
    animation: infinite 1.5s linear scrollBounce;
    display: block;
    flex-shrink: 0;
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: none;
    position: relative;
    margin: auto auto 60px;
  }
}

@keyframes scrollBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
