@import '../../../Abstracto.scss';

//Lista de colores
$colors: (
  menta: #27e5c7,
  gris: #dadada,
  blanco: #ffffff,
  aqua: #00e1d7,
  naranja: #fc5936,
  rojo: #b92e1f
);

//Loop grid color
@each $name, $color in $colors {
  .color-#{$name} {
    background: $color;
  }
}

#articulos-abstracto-plus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 40px 0 60px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 10;
  background: #f7f7f7;

  h1 {
    text-align: center;
    line-height: 40px;
    margin: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 40px;
    width: 95%;

    @media screen and (min-width: 768px) {
      //Lista de Frs
      $frs: (
        tres: 3,
        dos: 2,
        uno: 1,
        cuatro: 4,
        dosuno: 2,
        dosdos: 2
      );

      @each $numero, $fr in $frs {
        .#{$numero}.#{$numero} {
          grid-column-start: span $fr;
        }
      }

      .dosuno.dosuno {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }

    @media screen and (min-width: 1152px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      .tres.tres {
        grid-column-start: span 3;
      }

      .dos.dos {
        grid-column-start: span 3;
      }

      .uno.uno {
        grid-column-start: span 2;
      }

      .dosdos.dosdos {
        grid-column-start: span 3;
      }
    }

    @media screen and (min-width: 1440px) {
      .cuatro.cuatro {
        grid-column-start: span 3;
      }
    }

    .card {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      text-align: center;
      border: 2px solid #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      grid-column-start: span 5;

      h1 {
        font-family: Avenir LT Std;
        font-weight: 900;
        line-height: 30px;
      }
    }
  }
}

#categorias-abstracto-plus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  box-sizing: border-box;

  h1 {
    color: black;
    font-family: Avenir LT Std;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }

  .categorias-dropdown {
    margin: 0 16px;

    select {
      background: #ffffff;
      border: 2px solid #000000;
      height: 38px;
      width: 290px;
      margin-top: 20px;
      font-family: Avenir LT Std;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      option {
        background-color: #f7f7f7;
        color: black;
      }
    }
  }

  div.banda-categoria {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    text-align: center;
    color: white;
    height: 60px;
    background-color: black;
    width: 100%;

    span {
      font-weight: bold;
      font-size: 23px;
      line-height: 40px;
    }
  }

  .contenedor-cards {
    margin: 40px 16px 0;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 25px;

      iframe {
        display: block;
        text-align: justify;
        margin: 0;
        max-width: 100%;
        width: 288px;
        height: 162px;
      }

      span {
        font-family: Roboto Mono;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        text-align: center;
        padding: 24px;
      }
      .descripcion {
        display: none;
      }
    }
  }
}

#card-abstracto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 24px 16px;
  padding: 10px;

  .titulo-card {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin: 24px;
    width: 100%;
    text-align: left;
  }

  img {
    width: 240px;
    height: 170px;
    box-shadow: none;
    border-radius: 0;
    object-fit: cover;
  }

  .leer-articulo {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 50px;
    color: #fc5936;

    &.anchor {
      box-shadow: none;
    }
  }
}

#categorias-articulo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 60px;
  max-width: 100%;
  position: relative;
  z-index: 10;
  background: #f7f7f7;

  .categorias-izquierdo {
    width: 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: Avenir LT Std;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: black;

    @media screen and (min-width: 768px) {
      position: sticky;
      top: 60px;
    }

    @media screen and (min-width: 1152px) {
      width: 23%;
      max-width: 23%;
    }

    span {
      padding-left: 60px;
    }

    hr {
      width: 45%;
      padding-left: 60px;
      margin: 10px 5px 0 auto;
      border: 1px solid #c4c4c4;

      @media screen and (min-width: 1152px) {
        width: 50%;
      }

      @media screen and (min-width: 1440px) {
        width: 60%;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 40px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      li {
        margin-top: 12px;
        padding: 0 0 0 60px;
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover,
        &.active {
          display: block;
          color: white;
          background-color: #000000;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
        }
        a {
          padding-left: 60px;
        }
      }
    }
  }

  .categorias-derecho {
    width: 70%;
    max-width: 70%;
    padding-left: 20px;
    padding-top: 5px;

    @media screen and (min-width: 1152px) {
      width: 77%;
      max-width: 77%;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 30px;

      @media screen and (min-width: 1152px) {
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
      }

      iframe {
        display: block;
        text-align: justify;
        margin: 0;
        max-width: 100%;
        width: 288px;
        height: 162px;

        @media screen and (min-width: 768px) {
          width: 388px;
          height: 218px;
        }

        @media screen and (min-width: 1440px) {
          width: 485px;
          height: 272px;
        }
      }

      span {
        font-family: Roboto Mono;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        color: #000000;
        text-align: start;
        padding: 24px;
        padding-bottom: 80px;

        @media screen and (min-width: 1440px) {
          padding-bottom: 20px;
        }
      }

      .descripcion {
        display: none;

        @media screen and (min-width: 1440px) {
          font-family: Avenir LT Std;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          display: inline;
          padding-top: 0;
          padding-bottom: 80px;
        }
      }
    }

    .banda-derecho {
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 40px;
      color: #000000;
    }
    .contenedor-cards-articulo {
      padding: 60px 85px 80px 35px;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (min-width: 1152px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }

      @media screen and (min-width: 1440px) {
        grid-template-columns: 468px 468px;
        grid-gap: 25px;
      }

      #card-abstracto {
        height: 490px;
        margin: 0 0 20px 0;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .titulo-card {
          font-family: Roboto Mono;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 35px;
          color: #000000;
          margin: 0 auto 10px;
          min-height: 170px;
          width: 100%;
          text-align: left;

          @media screen and (min-width: 1152px) {
            margin: 0 auto 25px;
          }

          @media screen and (min-width: 1152px) {
            margin: 0 auto 30px;
          }
        }

        img {
          width: 100%;
          height: 229px;
          align-self: center;
        }

        .leer-articulo {
          margin-top: 20px;
          box-shadow: none;
        }
      }
    }
  }
}
