$mobile: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1151px)';
$laptop: '(min-width: 1152px) and (max-width: 1439px)';
$desktop: '(min-width: 1440px)';

nav.nav-vert {
  position: absolute;
  top: 7px;
  right: 0;
  bottom: 0;
  width: 104px;
  height: 66px;
  z-index: 1000;
  @media #{$tablet} {
    top: 7px;
  }
}

.toggle-vert {
  outline: none;
  border: none;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 21.5px;
  right: 18.5px;
  height: 23px;
  width: 23px;
  padding: 0;
  box-shadow: none;
  background: transparent;
  z-index: 1001;
  @media #{$tablet} {
    right: 42.5px;
  }
}

ul.menu-list-vert,
li.menu-item-vert {
  margin: 0;
  padding: 0;
}

ul.menu-list-vert {
  margin-top: -42px;
  padding-top: 42px;
  position: absolute;
  top: 0;
  left: calc(-100vw + 120px);
  width: 100vw;
  height: 100vh;
  background-color: black;
  transition: linear 0.1s;
  z-index: 1000;
  @media #{$tablet} {
    margin-top: -80px;
    padding-top: 80px;
    left: calc(-100vw + 134px);
  }
  .logo {
    margin: 10px 0 22px 26px;
    @media #{$tablet} {
      margin: 15px 0 22px 53px;
    }
  }
  hr {
    border: 0.5px solid #000000;
    margin: 0 16px;
    @media #{$tablet} {
      margin: 0 40px 20px;
    }
  }
  .line-item-vert {
    border: 1px solid #e5e5e5;
    margin: 0 35px 20px;
    @media #{$tablet} {
      margin: 0 105px 20px;
    }
  }
  &.hidden {
    display: none;
  }
}

li.menu-item-vert {
  list-style: none;
  height: 60px;
  width: auto;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  margin: 0 35px;
  @media #{$tablet} {
    margin: 0 105px;
  }
  p {
    margin: 0;
    font-family: 'Avenir LT Std';
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
  }
}

.icon-placeholder-vert {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder-vert {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh-vert {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
