@import '../../Abstracto.scss';

.error-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 16px;
  background-color: $blanco;
  box-sizing: border-box;

  &__image {
    width: 180px;
    height: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    @include tablet {
      width: 347px;
    }

    @include desktop {
      width: 488px;
    }
  }

  &__header {
    margin: 40px auto 0;
    font-weight: 700;
    text-align: center;
    line-height: 25px;

    @include tablet {
      margin-top: 60px;
    }
  }

  &__paragraph {
    margin: 24px auto 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    @include tablet {
      margin-top: 15px;
    }
  }

  &__enlace {
    margin: 0 5px 0;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    color: #fc5936;

    @include tablet {
      font-size: 28px;
    }

    @include laptop {
      font-size: 24px;
    }
  }
}
