@import '../../Abstracto.scss';

.aviso-privacidad {
  max-width: 800px;
  margin: auto;
  padding: 100px 16px 100px;
  @include tablet {
    padding: 150px 16px 100px;
  }
  h2 {
    font-family: 'Roboto Mono';
    line-height: normal;
    padding-top: 20px;
  }
}
