@import '../../../Abstracto.scss';

input.input-search-mobile {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  background: #f7f7f7;
  border: 2px solid #000000;
  height: 60px;
  width: 100%;
  font-family: Avenir LT Std;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  box-sizing: border-box;
  padding: 0 16% 0 20px;
  position: relative;
  z-index: 10;

  &::placeholder {
    color: black;
  }
}

div.parent-input-search-tablet-videos {
  background-color: #27e5c7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  box-shadow: none;
  margin-top: -1px;
  position: relative;
  z-index: 10;
}

.parent-input-search-tablet-articulos {
  background-color: #fc5936;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  box-shadow: none;
  margin-top: -1px;
  position: relative;
  z-index: 10;
}

input.input-search-tablet {
  background: #f7f7f7;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  height: 60px;
  width: 100%;
  font-family: Avenir LT Std;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  padding: 0 8.3% 0 40px;
  position: relative;
  z-index: 10;

  @media screen and (min-width: 1152px) {
    padding: 0 6.6% 0 40px;
  }

  &::placeholder {
    color: black;
  }
}

.seccion-flex {
  width: 100%;
  position: relative;
  z-index: 10;

  cursor: pointer;

  .articulos,
  .videos {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #fc5936;
    font-size: 25px;

    span {
      color: white;
    }

    &--collapsed {
      height: 43px;
      span {
        margin: auto;
      }
    }

    &--expanded {
      height: auto;
      span {
        width: 100%;
        height: 100%;
        padding: 30px 0;
        margin: 0;
      }
    }
  }

  .videos {
    background-color: #27e5c7;
  }
}

.seccion-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  position: relative;
  z-index: 10;

  cursor: pointer;

  .videos,
  .articulos {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #27e5c7;
    font-size: 25px;
    grid-column-start: span 3;

    span {
      color: white;
      width: 100%;
      height: 100%;
      padding: 30px 0;
      margin: 0;
    }

    &--collapsed {
      grid-column-start: span 2;
      span {
        margin: auto;
      }
    }

    &--expanded {
      grid-column-start: span 4;

      &.articulos--full,
      &.videos--full {
        grid-column-start: span 3;
      }

      span {
        width: 100%;
        height: 100%;
        padding: 30px 0;
        margin: 0;
      }
    }
  }

  .articulos {
    background-color: #fc5936;
  }
}

.input-container {
  display: flex;
  position: relative;
  width: 100%;
  padding: 30px 35px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;

  img {
    position: absolute;
    width: 24px;
    top: 40%;
    right: 15%;
    box-sizing: border-box;
    box-shadow: none;
    z-index: 11;

    @media screen and (min-width: 768px) {
      top: 42%;
      right: 8%;
    }

    @media screen and (min-width: 1152px) {
      right: 5%;
    }
  }
}
