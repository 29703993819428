@import './Abstracto.scss';

body {
  margin: 0;
  font-family: 'Roboto Mono', 'Avenir LT Std', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Roboto Mono', 'Avenir LT Std', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//Design System
body {
  background-color: #f7f7f7;
  overflow-x: hidden;
}

//Type Scale
h1 {
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  font-family: 'Roboto Mono', monospace;
}

h2 {
  font-size: 25px;
  line-height: 50px;
  font-family: 'Avenir LT Std Medium';
}

h3 {
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: 'Avenir LT Std Heavy';
}

h4 {
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: 'Avenir LT Std Roman';
}

body {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Avenir LT Std Medium';
  width: 100%;
}

button {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Roboto Mono', monospace;
}

p {
  font-weight: 500;
  size: 16px;
  line-height: 30px;
  font-family: 'Roboto Mono';
}

p.avenir {
  font-weight: 500;
  size: 16px;
  line-height: 30px;
  font-family: 'Avenir LT Std Heavy';
}

//Shadows
img {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: 0.1s linear;
  &:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  }
}

button {
  background-color: transparent;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
}

//SVGs

.arrow {
  width: 24px;
  height: 24px;
}

.small-a {
  width: 23px;
  height: 20px;
  position: fixed;
  top: 91px;
  left: 100px;
  box-shadow: none;
  z-index: 11;
  @media #{$tablet} {
    left: 40px;
  }
  @media #{$mobile} {
    left: 16px;
    top: 53px;
  }
  @include laptop {
    margin-left: 100px;
    left: auto;
  }
  &.active {
    opacity: 1;
    transition: all 150ms ease-in;
  }
  &.hidden {
    opacity: 0;
    transition: all 150ms ease-out;
  }
}

.small-logo {
  width: 23px;
  height: 20px;
  margin-top: 50px;
  margin-left: 16px;
  @media #{$tablet} {
    margin-left: 40px;
    margin-top: 80px;
  }
  @media #{$laptop} {
    margin-top: 80px;
  }
  @media #{$desktop} {
    margin-top: 80px;
  }
  .link {
    transition: 0.5s linear;
  }
}

a {
  text-decoration: none;
  color: unset;
}

//Fonts
@font-face {
  font-family: 'Avenir LT Std';
  src: local('Avenir LT Std'),
    url(./assets/fonts/AvenirLTStd-Roman.otf) format('opentype');
}

@font-face {
  font-family: 'Avenir LT Std Roman';
  font-weight: 500;
  src: local('Avenir LT Std'),
    url(./assets/fonts/AvenirLTStd-Roman.otf) format('opentype');
}

@font-face {
  font-family: 'Avenir LT Std Medium';
  font-weight: 600;
  src: local('Avenir LT Std'),
    url(./assets/fonts/AvenirLTStd-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Avenir LT Std Heavy';
  font-weight: 800;
  src: local('Avenir LT Std'),
    url(./assets/fonts/AvenirLTStd-Heavy.otf) format('opentype');
}

.tooltip-info {
  text-decoration: dotted;
  text-decoration-color: black;
  text-decoration-line: underline;
}

.react-toast-notifications__toast {
  z-index: 99999;
  height: 66px;
  > :first-child {
    width: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 66px;

    &::after {
      height: 46px;
      width: 46px;
      content: '';
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      background-image: url(./assets/svgs/logo-a.svg);
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }

    svg {
      display: none !important;
    }
  }
}

.react-toast-notifications__toast--error {
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #ff6e67;
    }

    &::after {
      content: '';
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      background-image: url(./assets/svgs/x-circle.svg);
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }
  }

  :nth-child(2) {
    color: #ff6e67;
  }

  :nth-child(3) {
    color: #050505;
    opacity: 1;
  }
}

.react-toast-notifications__toast--warning {
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #f5be30;
    }

    &::after {
      content: '';
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      background-image: url(./assets/svgs/alert-triangle.svg);
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
    }
  }

  :nth-child(2) {
    color: #f5be30;
  }

  :nth-child(3) {
    color: #050505;
    opacity: 1;
  }

  &.css-fwfomi-ToastElement {
    color: #050505;
  }
}

.react-toast-notifications__toast--info {
  z-index: 99999;
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #406aea;
    }
  }

  :nth-child(2) {
    background-color: #d6e8ff;
    color: #0070ff;
  }

  :nth-child(3) {
    background-color: #d6e8ff;
    opacity: 1;
    color: #050505;
  }
}

.react-toast-notifications__toast--success {
  z-index: 99999;
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #31ac78;
    }
  }

  :nth-child(2) {
    background-color: #e9fff2;
  }

  :nth-child(3) {
    opacity: 1;
    color: #050505;
  }
}

.react-toast-notifications__container {
  height: 66px;
  padding: 0 !important;
  margin: 8px;
  overflow: hidden !important;
  > :first-child {
    height: 66px !important;
  }
}

.react-toast-notifications__toast__icon-wrapper {
  padding: 0 !important;
}
