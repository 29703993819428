$mobile: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1151px)';
$laptop: '(min-width: 1152px) and (max-width: 1439px)';
$desktop: '(min-width: 1440px)';

nav.nav-hori {
  position: absolute;
  top: 23px;
  right: 0;
  bottom: 0;
  width: 104px;
  height: 66px;
  z-index: 1000;
}

.toggle-hori {
  outline: none;
  border: none;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 7.5px;
  right: 23px;
  height: 23px;
  width: 23px;
  padding: 0;
  box-shadow: none;
  background: transparent;
  z-index: 1001;
}

ul.menu-list-hori,
li.menu-item-hori {
  margin: 0;
  padding: 0;
}

ul.menu-list-hori {
  position: absolute;
  top: 0;
  left: calc(-100vw + 181px);
  margin-top: -30px;
  width: 100vw;
  height: 92px;
  background-color: black;
  transition: linear 0.1s;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  @media #{$desktop} {
    left: calc(-100vw + 181px);
  }
  .logo {
    position: absolute;
    top: 31px;
    left: 100px;
    margin: 0;
  }
  .line-item-hori {
    border: 1px solid #e5e5e5;
    margin: 0 35px 20px;
  }
  .menu-items {
    display: grid;
    grid-template-columns: 67px 98px 90px 68px 81px 139px 44px 69px;
    column-gap: 10px;
    height: 100%;
    width: fit-content;
    margin-right: calc(((100vw - 952px) / 2) + 52px);
    align-items: center;
    @media #{$desktop} {
      margin-right: calc(((100vw - 1240px) / 2) + 52px);
    }
  }
  &.hidden {
    display: none;
  }
  a {
    height: stretch;
    overflow: hidden;
  }
}

li.menu-item-hori {
  list-style: none;
  width: auto;
  height: 100%;
  cursor: pointer;
  z-index: 1000;
  p {
    margin: 0;
    font-family: 'Avenir LT Std';
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
  }
}

.icon-placeholder-hori {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder-hori {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh-hori {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
